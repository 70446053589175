import { PaginationResponse } from '@components/pagination/interface'
import {
  BorrowingBaseRequest,
  BorrowingBaseResponse,
  CollateralListUploadRequest,
  CollateralPoolRequest,
  CollateralPoolResponse,
  CollateralRequest,
  CollateralResponse,
  HistoricalSurplusRequest,
  HistoricalSurplusResponse,
} from '@interfaces/manage-monitor-borrowing-base'

import manageApiClient from './client'

export class BorrowingBaseService {
  static async get(params: BorrowingBaseRequest) {
    const { slug_name, facility_id, calculation_date } = params
    const { data } = await manageApiClient.get<BorrowingBaseResponse[]>(
      `/borrowing-base/${slug_name}/${facility_id}/${calculation_date}`
    )
    return data
  }

  static async historicalSurplus(params: HistoricalSurplusRequest) {
    const { slug_name, facility_id } = params
    const { data } = await manageApiClient.get<HistoricalSurplusResponse[]>(
      `/borrowing-base/${slug_name}/${facility_id}/historical-surplus`
    )
    return data
  }

  static async collateralPool(params: CollateralPoolRequest) {
    const { slug_name, facility_id, calculation_date } = params
    const { data } = await manageApiClient.get<CollateralPoolResponse[]>(
      `/borrowing-base/collateral/${slug_name}/${facility_id}/${calculation_date}/pool`
    )
    return data
  }

  static async collateral(params: CollateralRequest) {
    const { slug_name, facility_id, calculation_date, pool, page, per_page } =
      params
    const { data } = await manageApiClient.get<
      PaginationResponse<CollateralResponse[]>
    >(
      `/borrowing-base/collateral/${slug_name}/${facility_id}/${calculation_date}/${page}/${per_page}${
        pool ? `/${pool}` : ''
      }`
    )
    return data
  }

  static async uploadCollateralReportData(params: CollateralListUploadRequest) {
    const { data } = await manageApiClient.post(
      `/borrowing-base/collateral/upload-report-file-invoker`,
      params
    )
    return data
  }

  static async getMasterFacilityOverviewData(
    slugName: string,
    facilityID: number
  ) {
    const { data } = await manageApiClient.get<any[]>(
      `/borrowing-base/${slugName}/${facilityID}`
    )
    return data
  }
}
