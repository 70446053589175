/* eslint-disable */
import React, { useContext, useState } from 'react'
import { useMutation, useQuery } from 'react-query'

import Typography from '@components/atoms/typography'
import CCButton from '@components/buttons/cc-button'
import DynamicMenu from '@components/dynamic-menu'
import BaseLayout from '@components/layouts/base-layout'
import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { EyeIcon } from '@heroicons/react/24/outline'
import { Card } from '@material-tailwind/react'
import { NotificationsService } from '@services/api-notifications/notifications'
import Pagination from '@components/pagination'

import SettingsDialog from './settings-dialog'

const PAGE_COUNT = 50

const NotificationLogs = () => {
  const { company } = useContext(AuthContext)
  const [selectedNotifications, setSelectedNotifications] = useState<any>(
    new Set()
  )

  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(PAGE_COUNT)

  const { data, isLoading, refetch } = useQuery(
    ['getNotifications', company?.slug_name],
    () => {
      const slugName = company?.slug_name
      if (slugName) {
        return NotificationsService.getNotifications(slugName)
      }
    },
    { ...getStaleMins(), enabled: !!company?.slug_name }
  )

  const { mutate: putNotificationReadStatus } = useMutation({
    mutationFn: ({ ids, isRead }: { ids: any[]; isRead: boolean }) => {
      return NotificationsService.setReadStatus(ids, isRead)
    },
    onSuccess: () => {
      console.log('success')
      refetch()
    },
    onError: () => {
      console.log('failed putNotificationReadStatus')
    },
  })

  const toggleEntity = (entity: any) => {
    setSelectedNotifications((prev: any) => {
      const updatedSet = new Set(prev)
      if (updatedSet.has(entity)) {
        updatedSet.delete(entity) // Remove if it was already selected
      } else {
        updatedSet.add(entity) // Add if not selected
      }
      return updatedSet
    })
  }

  const columns = [
    {
      title: '',
      align: 'center',
      field: '',
      render: (entity: any) => {
        const selection = entity.name // to change after database is hooked
        const isSelected = selectedNotifications.has(selection)
        return (
          <label className="switch">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => toggleEntity(selection)}
            />
            <span className="slider"></span>
          </label>
        )
      },
    },
    {
      title: 'Date & Time',
      align: 'center',
      field: 'name', // to change after database is hooked
    },
    {
      title: 'Category',
      field: 'severity', // to change after database is hooked
      align: 'center',
    },
    {
      title: 'Notification',
      align: 'left',
      render: (e: any) => {
        // to change after database is hooked
        return (
          <div className="flex justify-between items-center">
            <div>{e.status}</div>
            <div
              className={`${
                e.severity === 'WARNING' ? 'bg-blue' : ''
              } rounded-full w-2 h-2`}
            ></div>
          </div>
        )
      },
    },

    {
      title: '',
      align: 'center',
      field: '',
      render: (entity: any) => {
        const entityMenuItems = [
          {
            icon: <EyeIcon className="w-4" />,
            label: <div className="ml-2"> Mark as Read </div>,
            provider: () => {
              // change ids and readstatus after database is hooked
              putNotificationReadStatus({ ids: [123], isRead: true })
            },
          },
        ]

        return <DynamicMenu menuItems={entityMenuItems} />
      },
    },
  ]

  const handleMarkSelected = () => {
    const selectedNotificationsArray = [...selectedNotifications]
    putNotificationReadStatus({ ids: selectedNotificationsArray, isRead: true })
    setSelectedNotifications(new Set())
  }

  const handleMarkAll = () => {
    if (data) {
      putNotificationReadStatus({ ids: data, isRead: true })
      setSelectedNotifications(new Set())
    }
  }

  return (
    <BaseLayout title="xd">
      <Card className="p-6 rounded-md">
        <div className="flex items-center mb-6">
          <Typography
            variant="h6"
            className="font-semibold text-cc-text-primary"
          >
            Notifications
          </Typography>

          <div className="ml-auto flex items-center gap-3">
            <SettingsDialog />
            {selectedNotifications.size > 0 && (
              <button
                disabled={selectedNotifications.size === 0}
                className={`border border-cc-primary-gray-medium h-9 px-3 pr-4 rounded-md flex items-center gap-1`}
                onClick={handleMarkSelected}
              >
                <Typography className="font-medium text-sm">
                  Mark Selected as Read
                </Typography>
              </button>
            )}

            <CCButton onClick={handleMarkAll} disabled={false}>
              <Typography className="font-medium text-sm">
                Mark All as Read
              </Typography>
            </CCButton>
          </div>
        </div>
        <Table
          columns={columns as any}
          data={data}
          loading={isLoading}
          // for highlighting // to change after database is hooked
          rowClass={(rowData, rowIndex) => {
            const selected = rowData.name // to change after database is hooked
            if (selectedNotifications.has(selected)) {
              return 'bg-cc-primary-hover'
            }
            // to change after database is hooked
            return rowData.severity === 'WARNING'
              ? ''
              : 'bg-cc-tertiary-hover-deselected'
          }}
        />
        <Pagination
          id="notification-table-pagination"
          total={1} // total number of entries, change after database is hooked
          page={page}
          onChangePage={p => {
            setPage(p)
          }}
          limit={perPage}
          limits={Array(3)
            .fill('')
            .map((_, i) => PAGE_COUNT * (i + 1))}
          onChangeLimit={l => {
            setPage(0)
            setPerPage(l)
          }}
        />
      </Card>
    </BaseLayout>
  )
}
export default NotificationLogs
