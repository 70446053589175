import { useContext } from 'react'
import moment from 'moment'
import { useQuery } from 'react-query'

import AuthContext from '@contexts/auth'
import {
  DataResponse,
  RiskData,
  RiskDataResponse,
  RisksFilters,
} from '@interfaces/analytics-risk'
import { FilterItem } from '@interfaces/analytics-risk-filter-type-key'
import FilterService from '@services/api-analytics/risk-filter'

import { getStaleMins } from './stale-timer'

interface ResultProps {
  fetchedDataAggregated: RiskDataResponse<DataResponse>
  data: RiskData<DataResponse>
  growthRatios: any
  cohort: RiskData<DataResponse>
  avgTerm?: number
  avgTermIsFetched: boolean
  error: any
  isFetching: boolean
  isAggregate: boolean
}

export const useGetRiskData = (
  func: (arg0: RisksFilters) => any,
  id: string,
  useCohortDates: boolean,
  getAvgTerm = false,
  additionalFilters: Record<string, any> = {}
): ResultProps => {
  const { company, appliedFilters } = useContext(AuthContext)
  const {
    dateStartCohort,
    dateEndCohort,
    dateStart,
    dateEnd,
    categoryTypes = [],
    activeType,
    currency = 'USD',
  } = appliedFilters
  const isAggregate = activeType === 'All'

  const start = useCohortDates ? dateStartCohort : dateStart
  const end = useCohortDates ? dateEndCohort : dateEnd

  const avgFilters = {
    date_from: moment(start).format('YYYY-MM-DD'),
    date_to: moment(end).format('YYYY-MM-DD'),
    slug_name: company?.slug_name ?? '',
    filters: categoryTypes,
  }

  const {
    data: avgTerm,
    isFetching: avgTermIsFetching,
    isFetched: avgTermIsFetched,
  } = useQuery(
    ['avgTerm', avgFilters],
    () => FilterService.getAvgTerm(avgFilters),
    { ...getStaleMins(), enabled: getAvgTerm && categoryTypes?.length > 0 }
  )

  const filters: RisksFilters = {
    ...avgFilters,
    filters: [],
    currency,
    ...additionalFilters,
  }

  const {
    error: errorAggregated,
    data: fetchedDataAggregated,
    isFetching: isFetchingAggregated,
  } = useQuery(
    ['cashCollected', filters, true, [], id],
    () =>
      func({
        ...filters,
        is_aggregate: true,
      }),
    {
      ...getStaleMins(),
      enabled:
        !!company?.slug_name &&
        !!start &&
        !!end &&
        (!getAvgTerm || avgTermIsFetched),
    }
  )

  const {
    error: errorNonAggregated,
    data: fetchedDataNonAggregated,
    isFetching: isFetchingNonAggregated,
  } = useQuery(
    ['cashCollected', filters, false, categoryTypes, id],
    () =>
      func({
        ...filters,
        is_aggregate: false,
        filters: categoryTypes,
      }),
    {
      ...getStaleMins(),
      enabled: (!getAvgTerm || avgTermIsFetched) && isAggregate === false,
    }
  )

  const { data, ...growthRatios } =
    (isAggregate ? fetchedDataAggregated : fetchedDataNonAggregated) ?? {}

  const cohort = (data || []).filter(
    (v: RiskData<DataResponse>, i: number, a: RiskData<DataResponse>[]) =>
      a.findIndex(v2 => v2.cohort === v.cohort) === i
  )

  const error = isAggregate ? errorAggregated : errorNonAggregated
  const isFetching =
    avgTermIsFetching || isAggregate
      ? isFetchingAggregated
      : isFetchingNonAggregated

  return {
    fetchedDataAggregated,
    data,
    growthRatios,
    cohort,
    avgTerm,
    avgTermIsFetched,
    error,
    isFetching,
    isAggregate,
  }
}

export const createSeries = (
  isAggregate: boolean,
  categoryTypes: number[],
  activeVal: FilterItem[],
  currency: string,
  isTotal: boolean,
  defaultLabel: string,
  defaultTooltip: string
) => {
  if (isAggregate) {
    return [
      {
        label: defaultLabel,
        field: 'type_all',
        tooltipLabel: defaultTooltip,
        type: 'ColumnSeries' as const,
      },
    ]
  }

  return (categoryTypes || [])
    .map((ct: number) => {
      const typeVal = activeVal.find((av: FilterItem) => av.id === ct)
      return {
        order: typeVal?.order,
        label: typeVal?.type ?? '',
        tooltipLabel: `${typeVal?.type} ${isTotal ? '' : `(${currency})`}`,
        field: `type_${ct}`,
        isStack: true,
        isTotal: false,
        type: 'ColumnSeries' as const,
      }
    })
    .sort((a, b) => (a.order || 0) - (b.order || 0))
}
