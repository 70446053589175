import adminApiClient from './client'

export interface ValidationQuery {
  searchId?: string
  tests?: string[]
  severities?: string[]
}

export interface ValidationSubset {
  results: any[]
  total: number
}
export interface ValidationSelection {
  [key: string]: {
    tests: string[]
    severities: string[]
  }
}

export class QuarantineService {
  static async getTests(slugName: string) {
    const { data } = await adminApiClient.get<[]>(`/quarantine/${slugName}`)
    return data
  }

  static async downloadTestReports(slugName: string, reports: string[]) {
    const params = new URLSearchParams()
    reports.forEach(report => {
      params.append('reports', report)
    })
    const url = `/quarantine/reports/${slugName}?${params.toString()}`
    const { data } = await adminApiClient.get<[]>(url)
    return data
  }

  static async getLastUpdated(slugName: string) {
    const url = `/quarantine/results/last-updated/${slugName}`

    const { data } = await adminApiClient.get<[]>(url)
    return data
  }

  static async getValidationSelections(slugName: string) {
    const url = `/quarantine/results/${slugName}`

    const { data } = await adminApiClient.get<ValidationSelection>(url)
    return data
  }

  static async getValidationSubset(
    slugName: string,
    type: string,
    offset: number,
    limit: number,
    searchId: string | undefined,
    tests: string[] | undefined,
    severities: string[] | undefined
  ): Promise<ValidationSubset> {
    const params = new URLSearchParams()

    params.append('offset', offset.toString())
    params.append('limit', limit.toString())
    if (searchId !== undefined) {
      params.append('id', searchId)
    }

    tests?.forEach(test => params.append('tests', test))
    severities?.forEach(severity => params.append('severities', severity))

    const queryString = params.toString()

    const url = `/quarantine/results/${slugName}/${type}?${queryString}`

    const { data } = await adminApiClient.get<ValidationSubset>(url)
    return data
  }
}
