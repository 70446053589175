import React, { useContext } from 'react'
import { useQuery } from 'react-query'

import Table from '@components/table'
import AuthContext from '@contexts/auth'
import { getStaleMins } from '@helpers/stale-timer'
import { Tooltip, Typography } from '@material-tailwind/react'
import { QuarantineService } from '@services/api-data-validation/quarantine'

const statusAppearance: { [key: string]: string } = {
  active: 'text-neutral-white bg-success-main border-success-main',
  inactive: 'text-neutral-body-1 bg-cc-secondary-hover-selected border-none',
}

const TestsTable = () => {
  const { company } = useContext(AuthContext)

  const { data, isLoading } = useQuery(
    ['quarantine-tests', company?.slug_name],
    () => {
      const slugName = company?.slug_name
      if (slugName) {
        return QuarantineService.getTests(slugName)
      }
    },
    { ...getStaleMins(), enabled: !!company?.slug_name }
  )

  const columns = [
    {
      title: '',
      align: 'center',
      className: 'cursor-text',
      render: (record: any) => {
        return (
          <Tooltip
            content={
              <div className="w-80">
                <Typography color="white" className="font-medium">
                  {record.name}
                </Typography>
                <Typography
                  variant="small"
                  color="white"
                  className="font-normal opacity-80"
                >
                  {record.description}
                </Typography>
              </div>
            }
            placement="right"
            animate={{
              mount: { opacity: 1, y: 0 },
              unmount: { opacity: 0, y: 25 },
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5 cursor-pointer text-blue-gray-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
          </Tooltip>
        )
      },
    },
    {
      title: 'Test Name',
      align: 'start',
      field: 'name',
      className: 'cursor-text',
    },
    {
      title: 'Severity',
      field: 'severity',
      align: 'start',
      className: 'cursor-text',
    },
    {
      title: 'Status',
      align: 'center',
      className: 'cursor-text',
      render: (record: any) => {
        return (
          <div
            className={`rounded-full px-3 py-1 w-[80px] ${
              statusAppearance[record.status]
            }`}
          >
            {record.status}
          </div>
        )
      },
    },
    {
      title: 'Count',
      field: 'count',
      align: 'center',
      className: 'cursor-text',
    },
  ]

  return <Table columns={columns as any} data={data} loading={isLoading} />
}

export default TestsTable
