import {
  AuditParamsBase,
  GenerateAudit,
  getAuditDataParams,
  reviewAuditItemParams,
  UpdateAuditSubsParams,
} from '@interfaces/manage-audit'

import manageApiClient from './client'

export class AuditService {
  static async getAllAudits(params: getAuditDataParams) {
    if (params.facility_id) {
      const { data } = await manageApiClient.get<any>(
        `/audit/${params.slug_name}/${params.facility_id}/all/${params.type}`
      )
      return data
    }
  }

  static async getAuditDataById(params: getAuditDataParams) {
    if (params.id != '' && params.facility_id) {
      const { data } = await manageApiClient.get<any>(
        `/audit/${params.slug_name}/${params.facility_id}/summary-by-id/${params.type}/${params.id}`
      )
      return data
    }
  }
  static async getAuditItemInfo(params: getAuditDataParams) {
    if (params.facility_id) {
      const { data } = await manageApiClient.get<any>(`/audit/item-by-id`, {
        params,
      })
      return data
    }
  }

  static async uploadForItem(formData: FormData) {
    if (formData.has('facility_id')) {
      const { data } = await manageApiClient.post<any[]>(
        `/audit/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data;',
          },
        }
      )
      return data
    }
  }

  static async reviewItem(params: reviewAuditItemParams) {
    if (params.facility_id) {
      const { data } = await manageApiClient.patch<any>(
        `/audit/review-item`,
        params
      )
      return data
    }
  }
  static async requestMoreInfo(params: reviewAuditItemParams) {
    if (params.facility_id) {
      const { data } = await manageApiClient.patch<any>(
        `/audit/request-more-info`,
        params
      )
      return data
    }
  }

  static async finaliseAudit(params: getAuditDataParams) {
    if (params.facility_id) {
      const { data } = await manageApiClient.patch<any>(
        `/audit/finalise-audit`,
        params
      )
      return data
    }
  }

  static async updateSubscribers(params: UpdateAuditSubsParams) {
    if (params.facility_id) {
      const { data } = await manageApiClient.patch<any>(
        `/audit/update-subscribers`,
        params
      )
      return data
    }
  }

  static async voidAudit(params: AuditParamsBase) {
    if (params.facility_id) {
      const { data } = await manageApiClient.patch<any>(
        `/audit/void-audit`,
        params
      )
      return data
    }
  }

  static async getAttachmentByKey(params: any) {
    if (params.slug_name && params.key && params.facility_id) {
      const { data } = await manageApiClient.get(
        `/audit/${params.slug_name}/${
          params.facility_id
        }/${params.audit_type.toLowerCase()}/attachment/${encodeURIComponent(
          params.key
        )}`,
        {
          responseType: 'arraybuffer',
        }
      )
      return data
    }
  }

  static async getReportData(params: getAuditDataParams) {
    if (params.facility_id) {
      const { data } = await manageApiClient.get<any>(
        `audit/${params.slug_name}/${params.facility_id}/${params.type}/${params.id}/report-data/${params.user_id}`
      )
      return data
    }
  }
  static async generateAudit(params: GenerateAudit) {
    if (params.facility_id) {
      const { data } = await manageApiClient.post<any>(
        `/audit/generate`,
        params
      )
      return data
    }
  }
}
