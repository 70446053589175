import React from 'react'

import Typography from '@components/atoms/typography'
import BaseLayout from '@components/layouts/base-layout'
import { Card } from '@material-tailwind/react'

// import ExportDialog from './tests-dialog'
import TestsTable from './tests-table'

const TestsL3 = () => {
  return (
    <BaseLayout title="tests">
      <Card className="p-6 rounded-md">
        <div className="flex justify-between items-center mb-6">
          <Typography
            variant="h6"
            className="font-semibold text-cc-text-primary"
          >
            Validation Tests
          </Typography>
          {/* <ExportDialog /> */}
        </div>
        <TestsTable />
      </Card>
    </BaseLayout>
  )
}

export default TestsL3
