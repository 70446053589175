import manageApiClient from './client'

export class FacilityDetailsService {
  static async getDealPartyDetailsRequest(params: {
    facility: string
    slug_name: string
    facility_id: number
  }) {
    const { data } = await manageApiClient.post<any[]>(
      `/deal-party-details/filter-by-facility`,
      params
    )
    return data
  }
}
