interface BankAccountBase {
  source: BankingSource
  officialName?: string
  nickname?: string
  accountNumber?: string
  mask?: string
  entityOwnership: string
  institutionName: string
  swiftCode?: string
  utilization: Utilization
  currency: string
  status: string
  fivetranSyncActive?: boolean
}

export interface UpsertBankAccount extends BankAccountBase {
  sourceID?: string
}

export interface BankAccount extends BankAccountBase {
  id: string
}

export enum BankingSource {
  BOTTOMLINE = 'bottomline',
  PLAID = 'plaid',
  SFTP = 'sftp',
}

export enum Utilization {
  COLLECTIONS = 'collections',
  OPERATIONS = 'operations',
  OTHER = 'other',
  RESERVE = 'reserve',
}
