import {
  DownloadParams,
  FinancialHistoryParams,
  FinancialsFileParams,
} from '@interfaces/accounting/financials'

import adminApiClient from './client'

export class AccountingService {
  static async uploadFile(params: FinancialsFileParams) {
    if (params.legalEntityID) {
      const formData = new FormData()
      params.file && formData.append('files', params.file)

      const { data } = await adminApiClient.post<any[]>(
        `accounting/financials-submissions/${params.slugName}/${params.legalEntityID}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      return data
    }
  }

  static async listFinancialsHistory(params: FinancialHistoryParams) {
    const { data } = await adminApiClient.get(
      `accounting/financials-history/${params.slugName}`,
      {
        params: {
          page: params.page,
          size: params.size,
          legal_entity_id: params.legalEntityID,
          start_date: params.startDate,
          end_date: params.endDate,
          file_name: params.fileName,
          state: params.state,
        },
      }
    )
    return data
  }

  static async reprocessFinancials(params: FinancialsFileParams) {
    if (params.legalEntityID) {
      const { data } = await adminApiClient.post(
        `accounting/reprocess-financials-history/${params.slugName}/${params.legalEntityID}`
      )
      return data
    }
  }

  static async voidLatestSubmission(params: FinancialsFileParams) {
    if (params.legalEntityID) {
      const { data } = await adminApiClient.post(
        `accounting/void-latest-financials-submission/${params.slugName}/${params.legalEntityID}`
      )
      return data
    }
  }

  static async downloadDoc(params: DownloadParams) {
    const { data } = await adminApiClient.get(
      `accounting/file-download-link/${params.slugName}`,
      { params }
    )
    return data
  }
}
