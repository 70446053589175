import { AxiosResponse } from 'axios'

import { UpsertDailyBalance } from '@interfaces/banking/balance-entry'
import {
  BankAccount,
  UpsertBankAccount,
} from '@interfaces/banking/bank-account'
import { CreatePlaidIntegration } from '@interfaces/plaid/create-plaid-integration'
import { PlaidIntegration } from '@interfaces/plaid/plaid-integration'

import adminApiClient from './client'

class BanksService {
  static async createAccount(
    slugName: string,
    bankInfo: UpsertBankAccount
  ): Promise<AxiosResponse<string>> {
    return adminApiClient.post(`banking/${slugName}/create`, {
      ...bankInfo,
    })
  }

  static async updateAccount(
    slugName: string,
    bankAccountID: string,
    bankInfo: UpsertBankAccount
  ): Promise<AxiosResponse<string>> {
    return adminApiClient.put(`banking/${slugName}/${bankAccountID}`, {
      ...bankInfo,
    })
  }

  static async listAccounts(
    slugName: string
  ): Promise<AxiosResponse<BankAccount[]>> {
    return adminApiClient.get(`banking/${slugName}`)
  }

  static async addAccountBalance(
    slugName: string,
    bankAccountID: string,
    balanceInfo: UpsertDailyBalance
  ): Promise<AxiosResponse<string>> {
    return adminApiClient.post(
      `banking/${slugName}/${bankAccountID}/balances/create`,
      {
        ...balanceInfo,
      }
    )
  }

  static async getPlaidLinkToken(
    slugName: string,
    accessToken?: string
  ): Promise<string> {
    const res = await adminApiClient.post(`plaid/${slugName}/link-token`, {
      token: accessToken,
    })

    return res?.data
  }

  static async setPlaidAccessToken(
    slugName: string,
    payload: CreatePlaidIntegration
  ): Promise<AxiosResponse<boolean>> {
    return await adminApiClient.post(`plaid/${slugName}/create-integration`, {
      ...payload,
    })
  }

  static async listInactivePlaidIntegrations(
    slugName: string
  ): Promise<AxiosResponse<PlaidIntegration[]>> {
    return await adminApiClient.get(
      `plaid/list/${slugName}?status=auth_required`
    )
  }

  static async updatePlaidAccessToken(
    slugName: string,
    accessToken: string
  ): Promise<AxiosResponse<boolean>> {
    return await adminApiClient.put(`plaid/${slugName}/activate`, {
      token: accessToken,
    })
  }
}

export default BanksService
