import {
  OverviewDataResponse,
  RiskDataResponse,
  RisksFilters,
} from '@interfaces/analytics-risk'

import apiClient from './client'

class OverviewService {
  static async getMetric(params: RisksFilters) {
    const { data } = await apiClient.post<
      RiskDataResponse<OverviewDataResponse>
    >('/risk-overview/metrics', params)

    return data
  }
}

export default OverviewService
