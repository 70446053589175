import {
  DashboardRequest,
  DashboardResponse,
} from '@interfaces/manage-monitor-dashboard'

import manageApiClient from './client'

export class DashboardService {
  static async getDashboards(params: DashboardRequest) {
    const { data } = await manageApiClient.get<DashboardResponse[]>(
      `/dashboard/${params.slug_name}/${params.facility}`
    )
    return data
  }

  static async getMasterFacilityOverviewData(
    slugName: string,
    facilityID: number
  ) {
    const { data } = await manageApiClient.get<any[]>(
      `/dashboard/${slugName}/${facilityID}/child-facilities`
    )
    return data
  }
}
