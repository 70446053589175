import React from 'react'
import { useSearchParams } from 'react-router-dom'

import FilterQuarantine from '@components/filters/filter-validations-query'
import L3TabsLayout from '@components/layouts/l3-tabs-layout'
import { TabContextProvider } from '@components/layouts/tabs-context'

import Validations from './validations'

export enum ValidationType {
  RECEIVABLES = 'receivables',
  CUSTOMERS = 'customers',
  TRANSACTIONS = 'transactions',
}

const ValidationsL3 = () => {
  const tabs = [
    {
      label: 'Receivables',
      component: <Validations type={ValidationType.RECEIVABLES} />,
      headerClassName: 'text-sm',
    },
    {
      label: 'Customers',
      component: <Validations type={ValidationType.CUSTOMERS} />,
      headerClassName: 'text-sm',
    },
    {
      label: 'Transactions',
      component: <Validations type={ValidationType.TRANSACTIONS} />,
      headerClassName: 'text-xs lg:text-sm',
    },
  ]

  const [searchParams] = useSearchParams()

  const tab = searchParams.get('tab')
  const tabMap: Record<string, number> = {
    receivables: 0,
    customers: 1,
    transactions: 2,
  }

  return (
    <TabContextProvider>
      <L3TabsLayout
        title="Validations"
        tabs={tabs}
        disableReset={false}
        filters={{
          left: (
            <FilterQuarantine
              tab={tab && tabMap[tab] !== undefined ? tabMap[tab] : 0}
            />
          ),
        }}
      />
    </TabContextProvider>
  )
}
export default ValidationsL3
