import { createContext } from 'react'
import { noop } from 'lodash'

export interface ApplicationCustomContextValues {
  showTable: boolean
  setShowTable: (isShow: boolean) => void
  showRawFinancials: boolean
  setShowRawFinancials: (isShow: boolean) => void
}

const ApplicationCustomContext = createContext<ApplicationCustomContextValues>({
  showTable: false,
  setShowTable: noop,
  showRawFinancials: true,
  setShowRawFinancials: noop,
})

export default ApplicationCustomContext
