import {
  DataResponse,
  RiskDataResponse,
  RisksFilters,
} from '@interfaces/analytics-risk'

import apiClient from './client'

export class TractionService {
  static async getOutstandingBalance(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/risk-traction/outstanding-principal',
      params
    )

    return data
  }

  static async getTotalLoans(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/risk-traction/outstanding-loan-count',
      params
    )

    return data
  }

  static async getMonthlyActiveClients(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/risk-traction/active-clients',
      params
    )

    return data
  }

  static async getTotalUniqueClients(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/risk-traction/total-unique-clients',
      params
    )

    return data
  }

  static async getVolume(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/risk-traction/volume-cohort',
      params
    )

    return data
  }
}
