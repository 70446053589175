import {
  AnalyticsCacheWarmerRequest,
  CacheWarmerResponse,
} from '@interfaces/cache-warmer'

import apiClient from './client'

class CacheWarmerService {
  static async warmup(params: AnalyticsCacheWarmerRequest) {
    const { data } = await apiClient.post<CacheWarmerResponse>(
      '/cache/warmup-invoker',
      params
    )
    return data
  }
}

export default CacheWarmerService
