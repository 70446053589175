import manageApiClient from './client'
/**
 * Series of MANAGE helper functions that get triggered everytime we "switch" clients
 */

const DEFAULT_ERR_MSG = 'N/A'

export interface HistoricalManageType {
  bankDataAsOf: string
  lastPipeline: string
  financialsAsOf: string
}

const DEFAULT_ERR_RESPONSE: HistoricalManageType = {
  bankDataAsOf: DEFAULT_ERR_MSG,
  lastPipeline: DEFAULT_ERR_MSG,
  financialsAsOf: DEFAULT_ERR_MSG,
}

export const fetchManageHistoricals = async (
  slug: any
): Promise<HistoricalManageType> => {
  try {
    const response = await manageApiClient.get(
      `/filters/historical-dates/${slug}`
    )

    const bankDataAsOf =
      response.data[0].bankDataAsOf?.split('T')[0] ?? 'Unavailable'
    const lastManagePipeline =
      response.data[0].lastUpdated?.split('T')[0] ?? 'Unavailable'
    const financialsAsOf =
      response.data[0].financialsAsOf?.split('T')[0] ?? 'Unavailable'
    return {
      bankDataAsOf: bankDataAsOf,
      lastPipeline: lastManagePipeline,
      financialsAsOf: financialsAsOf,
    }
  } catch (e) {
    return DEFAULT_ERR_RESPONSE
  }
}
