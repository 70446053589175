import {
  CharacteristicDataResponse,
  RisksFilters,
} from '@interfaces/analytics-risk'

import apiClient from './client'

export class CharacteristicService {
  static async getInterestRate(params: RisksFilters) {
    const { data } = await apiClient.post<CharacteristicDataResponse>(
      '/characteristics/interest-rate',
      params
    )

    return data
  }
  static async getTerms(params: RisksFilters) {
    const { data } = await apiClient.post<CharacteristicDataResponse>(
      '/characteristics/terms',
      params
    )

    return data
  }
  static async getValues(params: RisksFilters) {
    const { data } = await apiClient.post<CharacteristicDataResponse>(
      '/characteristics/values',
      params
    )

    return data
  }
}
