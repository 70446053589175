import apiClient from './client'
/**
 * Series of ANALYTICS helper functions that get triggered everytime we "switch" clients
 */

export interface HistoricalAnalyticsType {
  loanTapeAsOf: string
  lastPipeline: string
}

const DEFAULT_ERR_MSG = 'N/A'
const DEFAULT_ERR_RESPONSE: HistoricalAnalyticsType = {
  loanTapeAsOf: DEFAULT_ERR_MSG,
  lastPipeline: DEFAULT_ERR_MSG,
}

export const fetchAnalyticsHistoricals = async (
  slug: string
): Promise<HistoricalAnalyticsType> => {
  try {
    const response = await apiClient.get(`/client/historical-dates/${slug}`)
    const loanTapeAsof =
      response.data[0].loanTapeAsOf?.split('T')[0] ?? 'Unavailable'
    const lastAnalyticsPipeline =
      response.data[0].lastUpdated?.split('T')[0] ?? 'Unavailable'
    return {
      loanTapeAsOf: loanTapeAsof,
      lastPipeline: lastAnalyticsPipeline,
    }
  } catch (e) {
    console.log('analytics dates failing because: ', e)
    return DEFAULT_ERR_RESPONSE
  }
}
