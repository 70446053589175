import {
  LinkAccountRequest,
  VerifyAccountRequest,
} from '@interfaces/administrations-settings-social-login'
import { UserResponse } from '@interfaces/administrations-settings-user'

import adminApiClient from './client'

export class SocialLoginService {
  static async searchInvitedUser() {
    const { data } = await adminApiClient.get<UserResponse>(
      `/social-login/invited-user`
    )

    return data
  }

  static async verify({ user_id }: VerifyAccountRequest) {
    const { data } = await adminApiClient.post<boolean>(
      `/social-login/verify`,
      {
        user_id,
      }
    )

    return data
  }

  static async link({ user_id }: LinkAccountRequest) {
    const { data } = await adminApiClient.post<boolean>(`/social-login/link`, {
      user_id,
    })

    return data
  }
}
