import colormap from 'colormap'

export const redGreenHeatmap = colormap({
  colormap: [
    { index: 0, rgb: [89, 206, 135] }, // Green
    { index: 0.5, rgb: [255, 165, 87] }, // orange
    { index: 1, rgb: [255, 105, 105] }, // Red
  ],
  nshades: 101,
  format: 'hex',
  alpha: 1,
})
