import {
  CovenantDetailRequest,
  CovenantDetailResponse,
  CovenantListRequest,
  CovenantListResponse,
  CovenantRawResponse,
} from '@interfaces/manage-monitor-covenant'

import manageApiClient from './client'

export class CovenantService {
  static async getList(params: CovenantListRequest) {
    const { slug_name, facility_id, type, reports } = params
    const { data } = await manageApiClient.get<CovenantListResponse[]>(
      `/covenant/list/${slug_name}/${facility_id}/${type}/${
        reports ? 'report' : 'all'
      }`
    )
    return data
  }

  static async getDetail(params: CovenantDetailRequest) {
    const { slug_name, facility_id, type, id } = params
    const { data } = await manageApiClient.get<CovenantDetailResponse>(
      `/covenant/detail/${slug_name}/${facility_id}/${type}/${id}`
    )
    return data
  }

  static async getRaw(params: CovenantDetailRequest) {
    const { slug_name, facility_id, type, id } = params
    const { data } = await manageApiClient.get<CovenantRawResponse[]>(
      `/covenant/raw/${slug_name}/${facility_id}/${type}/${id}`
    )
    return data
  }
}
