import {
  DataResponse,
  RiskDataResponse,
  RisksFilters,
} from '@interfaces/analytics-risk'

import apiClient from './client'

class IndicatorService {
  static async getMetric(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/risk-indicators/metrics',
      params
    )

    return data
  }
}

export default IndicatorService
