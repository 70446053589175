import {
  WaterfallDetailRequest,
  WaterfallDetailResponse,
  WaterfallHistoryRequest,
  WaterfallHistoryResponse,
  WaterfallListResponse,
  WaterfallRequest,
  WaterfallSignRequest,
  WaterfallVoidRequest,
} from '@interfaces/manage-monitor-waterfall'

import manageApiClient from './client'

export class WaterfallService {
  static async getList({ slug, facilityId }: WaterfallRequest) {
    const { data } = await manageApiClient.get<WaterfallListResponse[]>(
      `/waterfall/${slug}/list/${facilityId}`
    )
    return data
  }

  static async getDetail({
    slug,
    facilityId,
    waterfall,
    calculationDate,
  }: WaterfallDetailRequest) {
    const { data } = await manageApiClient.get<WaterfallDetailResponse[]>(
      `/waterfall/details/${slug}/${facilityId}/${waterfall}/${calculationDate}`
    )
    return data
  }

  static async getHistory({
    slugName,
    facilityId,
    waterfallId,
  }: WaterfallHistoryRequest) {
    const { data } = await manageApiClient.get<WaterfallHistoryResponse[]>(
      `/waterfall/history/${slugName}/${facilityId}/${waterfallId}`
    )
    return data
  }

  static async submit(formData: FormData) {
    const { data } = await manageApiClient.post(`/waterfall/submit`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data;',
      },
    })
    return data
  }

  static async sign(params: WaterfallSignRequest) {
    const { data } = await manageApiClient.post(`/waterfall/sign`, params)
    return data
  }

  static async download({ slug, file }: { slug: string; file: string }) {
    const { data } = await manageApiClient.get(
      `/waterfall/download/${slug}?file=${file}`
    )
    return data
  }

  static async uploadProofOfTransaction(formData: FormData) {
    const { data } = await manageApiClient.post(
      `/waterfall/proof-of-transaction`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      }
    )
    return data
  }

  static async void({ slug, facilityId, waterfallId }: WaterfallVoidRequest) {
    const { data } = await manageApiClient.post(
      `/waterfall/void/${slug}/${facilityId}`,
      { waterfallId }
    )
    return data
  }
}
