import { ExchangeRateResponseDto } from '@interfaces/analytics-risk-currency-exchange'

import apiClient from './client'

class ExchangeService {
  static async getRate({
    from_currency,
    to_currency,
    date,
  }: {
    from_currency: string
    to_currency: string
    date: string
  }) {
    return apiClient.post<{ rate_to: number; rate_from: number }>(
      `currency-exchange/rate`,
      {
        from_currency,
        to_currency,
        date,
      }
    )
  }

  static getLatestISOCode = (code: string) => {
    /**
     * @description
     * Some currency codes has historical changes.
     * We need to make sure, code we're using to match with our fixer db pool is the latest one
     * @todo
     * Put this history or API/lib that possible to replace this function
     * https://en.wikipedia.org/wiki/ISO_4217#Historical_currency_codes
     */
    switch (code) {
      case 'GHC':
        return 'GHS'
      default:
        return code
    }
  }

  static async getHistoricalRate({
    slug_name,
    start_date,
    end_date,
  }: {
    slug_name: string
    start_date: string
    end_date: string
  }): Promise<ExchangeRateResponseDto[]> {
    const { data } = await apiClient.post(`exchange-rate/historical`, {
      slug_name,
      start_date,
      end_date,
    })

    return data
  }
}

export default ExchangeService
