import {
  FinancialKeyIndicatorsDataResponse,
  FinancialsFilters,
} from '@interfaces/financial'
import { OverviewDataResponse } from '@interfaces/risk'

import apiClient from './client'

export class FinancialKeyIndicatorService {
  static async getNetIncome(params: FinancialsFilters) {
    if (params?.entityId) {
      const { data } = await apiClient.get<FinancialKeyIndicatorsDataResponse>(
        `/financials/indicators/net-income/${params.slugName}/${params.entityId}`,
        { params }
      )

      return data
    }
  }

  static async getCash(params: FinancialsFilters) {
    if (params?.entityId) {
      const { data } = await apiClient.get<FinancialKeyIndicatorsDataResponse>(
        `/financials/indicators/cash/${params.slugName}/${params.entityId}`,
        { params }
      )

      return data
    }
  }
  static async getNetWorth(params: FinancialsFilters) {
    if (params?.entityId) {
      const { data } = await apiClient.get<FinancialKeyIndicatorsDataResponse>(
        `/financials/indicators/net-worth/${params.slugName}/${params.entityId}`,
        { params }
      )

      return data
    }
  }

  static async getRunway(params: FinancialsFilters) {
    if (params?.entityId) {
      const { data } = await apiClient.get<FinancialKeyIndicatorsDataResponse>(
        `/financials/indicators/runway/${params.slugName}/${params.entityId}`,
        { params }
      )

      return data
    }
  }

  static async getDebtEquityRatio(params: FinancialsFilters) {
    if (params?.entityId) {
      const { data } = await apiClient.get<FinancialKeyIndicatorsDataResponse>(
        `/financials/indicators/debt-to-equity/${params.slugName}/${params.entityId}`,
        { params }
      )

      return data
    }
  }

  static async getOverview(params: any) {
    if (params?.entityId) {
      const { data } = await apiClient.get<OverviewDataResponse>(
        `/financials/indicators/overview/${params.slugName}/${params.entityId}`,
        { params }
      )

      return data
    }
  }
}
