import {
  BaseParams,
  Entity,
  EntityBase,
  ListEntitiesParams,
} from '@interfaces/accounting/financials'

import adminApiClient from './client'

export class EntityService {
  static async listEntities(params: ListEntitiesParams) {
    const { data } = await adminApiClient.get(
      `accounting/list-legal-entities/${params.slugName}`,
      { params }
    )
    return data
  }

  static async getFilterEntities(params: BaseParams) {
    const { data } = await adminApiClient.get(
      `accounting/filter-legal-entities/${params.slugName}`,
      { params }
    )
    return data
  }

  static async getEntityById(params: Entity) {
    const { data } = await adminApiClient.get(
      `accounting/legal-entity/${params.slugName}/${params.entityId}`
    )
    return data
  }

  static async createEntity(params: EntityBase) {
    const { data } = await adminApiClient.post(
      `accounting/create-legal-entity`,
      params
    )
    return data
  }

  static async updateEntity(params: EntityBase) {
    const { data } = await adminApiClient.put(
      `accounting/update-legal-entity`,
      params
    )
    return data
  }

  //TODO void
}
