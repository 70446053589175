import { useContext } from 'react'

import AuthContext from '@contexts/auth'
import { Facility } from '@interfaces/facility'

export const useActiveFacility = () => {
  const { appliedFilters, optionFilters } = useContext(AuthContext)
  const { activeFacilityId } = appliedFilters
  const { facilities = [] } = optionFilters
  const activeFacility = facilities?.find(
    (f: Facility) => f.facility_id === activeFacilityId
  )

  return activeFacility
}
