import { AxiosResponse } from 'axios'

import adminApiClient from './client'

class AdminControlService {
  static async getFeatureFlags(
    slugName: string
  ): Promise<AxiosResponse<any[]>> {
    return await adminApiClient.get(`control/features/${slugName}`)
  }
}

export default AdminControlService
