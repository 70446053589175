import button from './button'
import checkbox from './checkbox'
import radio from './radio'
import select from './select'
import tooltip from './tooltip'
import typography from './typography'

const card = {
  Card: {
    defaultProps: {
      className: 'rounded-md', // Apply rounded-md to all Card components
    },
  },
}

export default {
  button,
  checkbox,
  select,
  tooltip,
  typography,
  radio,
  ...card,
}
