export const numberFormatter = (value?: any) => {
  return typeof value === 'number'
    ? Intl.NumberFormat(undefined, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(parseFloat((value ?? 0).toString()))
    : value
}

export const formatCurrency = (amount: number, currency = 'USD') => {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(amount)
}
