import { useContext } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { ToggleProps } from '@components/selectors/multi-option-buttons'
import { Role } from '@constants/role'
import AuthContext from '@contexts/auth'

export const getProviderName = (p?: string) => {
  if (p?.includes('google')) {
    return 'Google Account'
  } else if (p?.includes('windows')) {
    return 'Microsoft Account'
  } else {
    return 'Cascade Account'
  }
}

export const getProviderLogo = (p?: string) => {
  if (p?.includes('google')) {
    return 'https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA'
  } else if (p?.includes('windows')) {
    return 'https://learn.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_mssymbol_19.png'
  } else {
    return '/svgs/cascade-logo.svg'
  }
}

export const useUserAccessFeature = () => {
  const { userMetadata, company } = useContext(AuthContext)
  const { user } = useAuth0()
  const userCompanyAccess = userMetadata?.companies?.[company?.slug_name ?? '']
  const isSuperadmin = userMetadata?.isSuperadmin
  const permissions = userCompanyAccess?.access ?? {}
  const role = isSuperadmin ? Role.superadmin : userCompanyAccess?.control

  return {
    user,
    userCompanyAccess,
    role,
    filter_access: (feature = '', isInclude = true) => {
      return role !== Role.custom
        ? true
        : role === Role.custom &&
            !!Object.keys(permissions).find(
              aa =>
                (isInclude ? aa.includes(feature) : aa === feature) &&
                permissions[aa].includes('access')
            )
    },
    get_access: (feature = '', access = '') => {
      return role !== Role.custom
        ? true
        : role === Role.custom && permissions[feature]?.includes(access)
    },
    // Method to support the new decouple paradigm of our declarative toggles
    filterTogglesByAccess: (
      feature: string,
      tabs: ToggleProps['toggleSelections']
    ) => {
      return tabs.filter(t =>
        role !== Role.custom
          ? true
          : role === Role.custom &&
            !!Object.keys(permissions).find(
              aa =>
                aa.includes(`${feature}_${t.label.toLowerCase()}`) &&
                permissions[aa].includes('access')
            )
      )
    },
  }
}

export const useUserCategoryTypes = () => {
  const { userMetadata, company } = useContext(AuthContext)
  const userCompanyAccess = userMetadata?.companies?.[company?.slug_name ?? '']

  const user_category_types = userCompanyAccess?.category_types ?? ''

  return { user_category_types }
}
