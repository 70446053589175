export interface DownloadFileParams {
  bucketName: string
  fileName?: string
  folderName: string
  slug_name?: string
}

export interface EntityBase {
  slugName: string
  entityLegalName: string
  entityNickname: string
  geographies: string[]
  currency: string
}

export enum AccEntityStatus {
  ACTIVE = 'active',
  VOIDED = 'voided',
}

export enum TemplateStatus {
  ACTIVE = 'active',
  VOIDED = 'voided',
}

export interface Entity extends EntityBase {
  entityId: string
  status: AccEntityStatus
  useDefaultTemplate?: boolean
  templateID?: string
  lastUpload?: any
  ID?: string
}

export interface TemplateHistory {
  name: string
  templateId: string
  fileName: string
  status: TemplateStatus
}

export interface Template {
  name: string
  entityNickname: string
  entityID: string
  templateId: string
  fileName: string
  status: TemplateStatus
  history: TemplateHistory[]
}

export interface UploadFinancialsParams {
  entityId: string
}

export interface BaseParams {
  slugName: string
}

export interface ListEntitiesParams extends BaseParams, PaginationParams {}
export interface ListTemplatesParams extends BaseParams, PaginationParams {}

export interface DownloadParams extends BaseParams {
  pathName: string
}
export interface PaginationParams {
  page?: number
  size?: number
}

export interface FinancialHistoryParams extends BaseParams {
  page: number
  size: number
  state: string
  legalEntityID?: string
  startDate?: string
  endDate?: string
  fileName?: string
}

export interface FinancialsFileParams extends BaseParams {
  legalEntityID: string
  file?: File
}

export const enum FinancialUploadStatus {
  INREVIEW = 'needs_review',
  PROCESSING = 'processing',
  PROCESSED = 'processed',
  VOIDED = 'voided',
}

export interface FinancialsError {
  sheet: string
  cell: string
  reason: string
  systemMessage: string
}

export interface FinancialUpload {
  fileName: string
  dateUploaded: string
  state: FinancialUploadStatus
  s3Key: string
  errors?: FinancialsError[]
}
