import {
  DataResponse,
  RiskDataResponse,
  RisksFilters,
} from '@interfaces/analytics-risk'

import apiClient from './client'

class CohortService {
  static async getVintageAnalysis(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/cohort/vintage-analysis',
      params
    )

    return data
  }

  static async getCollectionCurve(params: RisksFilters) {
    const { data } = await apiClient.get<RiskDataResponse<DataResponse>>(
      '/cohort/collection-curve',
      { params }
    )

    return data
  }

  static async getRollRates(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/cohort/roll-rates',
      params
    )

    return data
  }

  static async getCohortRollRates(params: RisksFilters) {
    const { data } = await apiClient.post<RiskDataResponse<DataResponse>>(
      '/cohort/cohort-roll-rates',
      params
    )

    return data
  }
}

export default CohortService
