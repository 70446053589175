import React from 'react'

import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline'

const MaintenancePage = () => {
  return (
    <div className="bg-primary-surface-1 w-full h-[calc(100dvh)] flex flex-col justify-center p-8">
      <div className="flex items-center flex-col justify-center">
        <img
          src="/svgs/cascade-logo-long.svg"
          alt="Cascade"
          data-testid="cascade-logo"
          className="mt-6 mb-8 px-4 h-[80px]"
        />
        <div className="flex flex-1 flex-col items-center ">
          {/* <span className="text-[256px] font-bold text-transparent bg-clip-text bg-cc-primary-gray-dark opacity-30"> */}
          <WrenchScrewdriverIcon className="w-40 my-5 animate-[pulse_3s_ease-in-out_infinite]" />
          {/* </span> */}
          <span className="font-semibold text-4xl text-neutral-subtitle-2">
            Under Maintenance
          </span>
          <p className="text-neutral-body-2 text-base my-5">
            Thank you for your patience while we perform some important
            maintenance. We will be back shortly!
          </p>
          <p className="text-neutral-body-2 text-base">~ Cascade Debt Team ~</p>
        </div>
      </div>
    </div>
  )
}
export default MaintenancePage
