import axios from 'axios'
import FileSaver from 'file-saver'
import { toast } from 'react-toastify'

export const downloadS3File = (
  s3DownloadUrl: string,
  fileType: string,
  fileName?: string
) => {
  axios({
    method: 'get',
    url: s3DownloadUrl,
    responseType: 'blob',
  })
    .then(response => {
      const file = new Blob([response.data], { type: fileType })
      FileSaver.saveAs(file, fileName || getFileNameFromUrl(s3DownloadUrl))
    })
    .catch(error => {
      error.response.statusText && toast.error(error.response.statusText)
      return error
    })
}

const getFileNameFromUrl = (url: string) => {
  const matches = decodeURIComponent(url).match(/\/([^/]+)\.[^/]+$/)
  return matches ? matches[1] : 'file'
}

export const pdfDownloader = (data: any, fileName: string) => {
  const downloadLink = document.createElement('a')

  downloadLink.href = data
  downloadLink.download = fileName
  downloadLink.click()
}
