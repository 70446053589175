import { useContext } from 'react'

import AuthContext from '@contexts/auth'

export function useDisbursementCurrencies() {
  const { company, appliedFilters } = useContext(AuthContext)
  const { currency = 'USD' } = appliedFilters
  const disbursementCurrencies = company?.currencies_available
    ?.map(x => x.from_currency)
    .filter(x => x !== 'USD')

  return !disbursementCurrencies?.includes(currency)
}

export const getCurrencies = () => {
  const { company } = useContext(AuthContext)
  const availableCurrencies = (
    company?.currencies_available?.reduce((prev: string[], cur) => {
      const newCur = []
      if (cur?.to_currency && prev.findIndex(x => x === cur?.to_currency) < 0) {
        newCur.push(cur?.to_currency)
      }
      if (
        cur?.from_currency &&
        prev.findIndex(x => x === cur?.from_currency) < 0
      ) {
        newCur.push(cur?.from_currency)
      }

      return [...prev, ...newCur]
    }, []) ?? []
  ).filter(function (item, pos, self) {
    return item && self.indexOf(item) === pos
  })

  const hasUSD = availableCurrencies.find(x => x === 'USD')
  const currencies = hasUSD
    ? availableCurrencies
    : [...availableCurrencies, 'USD']

  return { availableCurrencies, currencies }
}
