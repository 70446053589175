import notificationsApiClient from './client'
import tempApiClient from './clienttemp'

export class NotificationsService {
  // CHANGE LATER
  static async getNotifications(slugName: string) {
    const { data } = await tempApiClient.get<[]>(`/quarantine/${slugName}`)
    return data
  }

  static async setReadStatus(notificationIds: any[], isRead: boolean) {
    await notificationsApiClient.put<void>('/set-read-status', {
      notification_ids: notificationIds,
      is_read: isRead,
    })
  }

  static async fetchUserSubscriptions() {
    // const {data} =await notificationsApiClient.get<any>('/user-subscriptions')
    // return data
    return [
      {
        channel: 'email',
        eventCategories: ['covenant', 'documents'],
      },
    ]
  }

  static async updateUserSubscriptions(subscriptions: any[]) {
    const { data } = await notificationsApiClient.put<any>(
      '/user-subscriptions',
      {
        subscriptions: subscriptions,
      }
    )
    return data
  }

  static async fetchSubscriptions() {
    // const { data } = await notificationsApiClient.get<string[]>(
    //   '/subscriptions'
    // )
    // return data
    return [
      {
        channel: 'email',
        eventCategories: [
          'covenant',
          'documents',
          'financials',
          'manage',
          'pipeline',
        ],
      },
    ]
  }
}
