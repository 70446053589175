import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import Sentry from './sentry/sentry.config'
import App from './app'
import reportWebVitals from './reportWebVitals'

import './index.css'

try {
  const container = document.getElementById('root')
  const root = createRoot(container!)
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  )
} catch (error) {
  //Need to nullify the sentry DSN on prod temporarily.
  if (process.env.REACT_APP_DSN_SENTRY !== 'null') {
    Sentry.captureException(error)
  } else {
    console.error(error)
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
