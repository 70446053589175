// Utility functions
export const getFromLS = (key: string) => {
  let ls
  if (localStorage) {
    try {
      ls = JSON.parse(localStorage.getItem(key) || '{}')
    } catch (e) {
      console.warn('an error occured during LS retrieval')
    }
  }
  return ls
}

export const saveToLS = (key: string, value: any) => {
  if (localStorage) {
    localStorage.setItem(key, JSON.stringify(value))
  }
}
