import React, { useContext, useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { useMutation, useQuery } from 'react-query'

import Button from '@components/atoms/button'
import Typography from '@components/atoms/typography'
import {
  Dialog,
  DialogBody,
  DialogFooter,
} from '@components/basic-dialog/custom-dialog'
import AuthContext from '@contexts/auth'
import { useToastNotification } from '@helpers/notification-hook'
import { Cog8ToothIcon } from '@heroicons/react/24/outline'
import { NotificationsService } from '@services/api-notifications/notifications'

const SettingsDialog = () => {
  const { company } = useContext(AuthContext)

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const { displayToastSuccess, displayToastError } = useToastNotification()

  const [subscriptions, setSubscriptions] = useState<Set<string>>(new Set())
  const [draftSubscriptions, setDraftSubscriptions] = useState<Set<string>>(
    new Set()
  )

  const { data: subscriptionList = [], isLoading: isLoadingSubscriptionList } =
    useQuery(['Notifications', 'subscriptions'], () =>
      NotificationsService.fetchSubscriptions()
    )

  const { data: initialSubscriptions } = useQuery(
    ['Notification-subscriptions', company?.slug_name],
    () => NotificationsService.fetchUserSubscriptions()
  )

  useEffect(() => {
    if (initialSubscriptions && initialSubscriptions.length > 0) {
      setSubscriptions(new Set(initialSubscriptions[0]['eventCategories']))
      setDraftSubscriptions(new Set(initialSubscriptions[0]['eventCategories']))
    }
  }, [initialSubscriptions])

  const { mutate: updateSubscriptions } = useMutation({
    mutationFn: () =>
      NotificationsService.updateUserSubscriptions(
        Array.from(draftSubscriptions)
      ),
    onSuccess: (data: any) => {
      displayToastSuccess('Succesfully saved settings.')
      setSubscriptions(data)
      setDraftSubscriptions(data)
    },
    onError: () => {
      displayToastError()
    },
  })

  const toggleEntity = (entity: any) => {
    setDraftSubscriptions((prev: Set<string>) => {
      const updatedSet = new Set(prev)
      if (updatedSet.has(entity)) {
        updatedSet.delete(entity)
      } else {
        updatedSet.add(entity)
      }
      return updatedSet
    })
  }

  let labels: any = <div>nothing yet</div>
  if (subscriptionList && subscriptionList.length > 0) {
    console.log(subscriptionList)
    labels = subscriptionList[0]['eventCategories'].map(option => {
      return (
        <div key={option} className="flex gap-5">
          <label className="switch">
            <input
              type="checkbox"
              checked={draftSubscriptions.has(option)}
              onChange={() => {
                toggleEntity(option)
              }}
            />
            <span className="slider"></span>
          </label>
          <div>{option}</div>
        </div>
      )
    })
  }

  const handleSave = async () => {
    updateSubscriptions()
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setDraftSubscriptions(subscriptions) // reset subscriptions
  }

  return (
    <div className="h-full">
      <button
        className="border border-cc-primary-gray-medium h-9 px-3 pr-4 rounded-md flex items-center gap-1"
        onClick={() => setOpenDialog(true)}
      >
        <Cog8ToothIcon className="text-cc-icon-primary h-6" strokeWidth={1.2} />
        <Typography className="font-medium text-sm">Settings</Typography>
      </button>
      <Dialog
        title="Notifications Settings"
        isOpen={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogBody>
          {isLoadingSubscriptionList ? (
            <div>loading</div>
          ) : (
            <div className="grid grid-cols-2 gap-y-3">{labels}</div>
          )}
        </DialogBody>
        <DialogFooter>
          <div className="flex justify-end">
            <Button
              onClick={handleSave}
              disabled={isEqual(subscriptions, draftSubscriptions)}
              className={`relative overflow-hidden border-[1.5px] py-2 px-6 border-primary-main text-primary-main 
            hover:bg-primary-hover hover:text-neutral-white hover:border-primary-hover
            disabled:text-cc-locked/50 disabled:bg-neutral-white disabled:cursor-not-allowed
            disabled:border-[1.5px]
            ${
              isEqual(subscriptions, draftSubscriptions) ? '' : 'animate-pulse'
            }`}
            >
              Save
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </div>
  )
}

export default SettingsDialog
