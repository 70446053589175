import {
  PaymentScheduleListRequest,
  PaymentScheduleListResponse,
  PaymentScheduleSummaryRequest,
  PaymentScheduleSummaryResponse,
} from '@interfaces/manage-monitor-cashflow'

import manageApiClient from './client'

export class CashflowService {
  static async getDisbursementSummary(params: PaymentScheduleSummaryRequest) {
    const { data } = await manageApiClient.get<PaymentScheduleSummaryResponse>(
      `/payment-schedule/${params.slugName}/summary/${params.facility}`
    )
    return data
  }

  static async getAllDisbursementdata(params: PaymentScheduleListRequest) {
    const { data } = await manageApiClient.get<PaymentScheduleListResponse>(
      `/payment-schedule/${params.slugName}/detail/${params.facility}/${params.disbursementDate}/${params.page}/${params.perPage}`
    )
    return data
  }
}
