import React, { useContext, useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'

import Pagination from '@components/pagination'
import Table from '@components/table'
import AuthContext from '@contexts/auth'
// import { getStaleMins } from '@helpers/stale-timer'
import {
  QuarantineService,
  ValidationSubset,
} from '@services/api-data-validation/quarantine'

import { ValidationType } from '.'

const PER_PAGE = 50
const LIMIT = 450

const Validations = ({ type }: { type: ValidationType }) => {
  const { company, appliedFilters } = useContext(AuthContext)
  const [failFetch, setFailFetch] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [offset, setOffset] = useState<number>(0)
  const [mutatedData, setMutatedData] = useState<ValidationSubset | undefined>(
    undefined
  )
  const prevParamsRef = useRef<string | null>(null)
  /* data is pulled when filters change and at inital loading */
  useEffect(() => {
    mutateData()
  }, [appliedFilters, offset])

  const { mutate: mutateData, isLoading: isLoadingData } = useMutation({
    mutationKey: ['Validations', prevParamsRef],
    mutationFn: () => {
      const slugName = company?.slug_name
      if (slugName) {
        const searchId = appliedFilters.validationQuery?.searchId
        const tests = appliedFilters.validationQuery?.descriptionSelection.map(
          (object: any) => object.value
        )
        const severities =
          appliedFilters.validationQuery?.severitySelection.map(
            (object: any) => object.value
          )
        prevParamsRef.current = `${slugName}-${type}-${searchId}-${tests}-${severities}-${offset}`

        return QuarantineService.getValidationSubset(
          slugName,
          type,
          offset,
          LIMIT,
          searchId,
          tests,
          severities
        )
      } else {
        return Promise.reject(new Error('Slug name is undefined'))
      }
    },
    onSuccess: (data: ValidationSubset) => setMutatedData(data),
    onError: () => {
      setFailFetch(true)
    },
  })

  useEffect(() => {
    setPage(0)
  }, [company?.slug_name])

  const columnTitleMap = {
    receivables: 'Disburse',
    customers: 'First Disburse',
    transactions: 'Transaction',
  }
  const columnFieldMap = {
    receivables: { date: 'disburse_date', amount: 'disburse_amount' },
    customers: { date: 'first_disburse_date', amount: 'first_disburse_amount' },
    transactions: { date: 'transaction_date', amount: 'transaction_amount' },
  }

  let columns = [
    {
      title: 'Affected Loan',
      field: 'affected_loan',
      align: 'center',
    },
    {
      title: `${columnTitleMap[type]} Date`,
      field: `${columnFieldMap[type].date}`,
      align: 'center',
    },
    {
      title: `${columnTitleMap[type]} Amount`,
      field: `${columnFieldMap[type].amount}`,
      align: 'center',
    },
    {
      title: 'Description',
      field: 'name',
    },
    {
      title: 'Severity',
      field: 'severity',
    },
  ]

  if (type === ValidationType.CUSTOMERS) {
    columns = [
      {
        title: 'Customer ID',
        field: 'entity_id',
        align: 'center',
      },
      ...columns,
    ]
  }

  if (type === ValidationType.TRANSACTIONS) {
    columns = [
      {
        title: 'Transaction ID',
        field: 'entity_id',
        align: 'center',
      },
      ...columns,
    ]
  }

  useEffect(() => {
    if (mutatedData) {
      const r = Math.ceil(mutatedData.results.length / perPage)
      const interval = Math.floor(page / r)
      if (offset !== interval) {
        setOffset(interval)
      }
    }
  }, [page])

  if (failFetch) {
    return (
      <div>
        Failed to fetch data. This may happen when you have filters that
        lingered while switching tabs. Press &apos;Reset&apos; to try again.
      </div>
    )
  }

  return (
    <div>
      <Table
        columns={columns as any}
        data={
          mutatedData && mutatedData.results
            ? mutatedData.results.slice(
                perPage *
                  (page % Math.ceil(mutatedData?.results.length / perPage)),
                page + 1 == Math.ceil(mutatedData?.results.length / perPage)
                  ? perPage * Math.ceil(mutatedData?.results.length / perPage)
                  : perPage *
                      ((page + 1) %
                        Math.ceil(mutatedData?.results.length / perPage))
              )
            : []
        }
        loading={isLoadingData}
      ></Table>
      <Pagination
        id="user-table-pagination"
        total={mutatedData ? mutatedData.total : 0} // total number of entries. we need this from BE
        page={page}
        onChangePage={p => {
          setPage(p)
        }}
        limit={perPage}
        limits={Array(3)
          .fill('')
          .map((_, i) => PER_PAGE * (i + 1))}
        onChangeLimit={l => {
          setPage(0)
          setPerPage(l)
        }}
      />
    </div>
  )
}

export default Validations
