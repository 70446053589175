import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '@components/atoms/button'
import AuthContext from '@contexts/auth'
import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline'

const NoMatchPage = () => {
  const { company } = useContext(AuthContext)
  const navigate = useNavigate()
  return (
    <div className="bg-primary-surface-1 w-screen h-[calc(100dvh)] flex flex-col justify-center items-center gap-6">
      <img
        src="/svgs/cascade-logo.svg"
        alt="Cascade"
        data-testid="cascade-logo"
        className="h-[36px]"
      />
      <span className="text-[192px] font-bold text-neutral-white bg-clip-text">
        404
      </span>
      <span className="font-semibold text-3xl text-neutral-white">
        Page Not Found
      </span>
      <p className="text-neutral-white text-neutral-body font-light">
        The requested page does not exist.
      </p>
      <Button
        onClick={() => navigate(`/?pid=${company?.slug_name}`)}
        className="text-cc-text-primary py-2 px-3"
      >
        <ArrowLeftCircleIcon className="w-[24px] h-[24px] mr-2 text-cc-primary-orange" />{' '}
        Dashboard Home
      </Button>
    </div>
  )
}
export default NoMatchPage
