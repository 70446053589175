import Company from '@interfaces/company'

import apiClient from './client'

class CommonService {
  static async getCompanies() {
    return apiClient.get<Company[]>(`client`)
  }
}

export default CommonService
