import { PaginationResponse } from '@components/pagination/interface'
import {
  CreateUserRequest,
  DeleteUserRequest,
  GetUsersRequest,
  ResendInvitationRequest,
  UpdateUserRequest,
  UserResponse,
  UserRolesResponse,
} from '@interfaces/administrations-settings-user'

import adminApiClient from './client'

export class UserService {
  static async getRoles() {
    const { data } = await adminApiClient.get<UserRolesResponse[]>(
      `/setting/roles`
    )

    return data
  }

  static async getUsers(params: GetUsersRequest) {
    const { data } = await adminApiClient.get<PaginationResponse<UserResponse>>(
      `/setting/users`,
      {
        params,
      }
    )

    return data
  }

  static async getUsersBasic(params: GetUsersRequest) {
    const { data } = await adminApiClient.get<PaginationResponse<UserResponse>>(
      `/setting/users-basic`,
      {
        params,
      }
    )
    return data
  }

  static async createUser(params: CreateUserRequest) {
    const { data } = await adminApiClient.post<UserResponse>(
      `/setting/user`,
      params
    )

    return data
  }

  static async resendInvitation(params: ResendInvitationRequest) {
    const { data } = await adminApiClient.post(
      `/setting/user/resend-invitation`,
      params
    )

    return data
  }

  static async updateUser(params: UpdateUserRequest) {
    const { data } = await adminApiClient.post<UserResponse>(
      `/setting/user/update`,
      params
    )

    return data
  }

  static async deleteUser(params: DeleteUserRequest) {
    const { data } = await adminApiClient.post<UserResponse>(
      `/setting/user/delete`,
      params
    )

    return data
  }

  static async getUserByEmail(email?: string) {
    const { data } = await adminApiClient.post<UserResponse>(
      `/setting/user/by-email`,
      { email }
    )

    return data
  }

  static async getUserById(id?: string) {
    if (!id) {
      return undefined
    }
    const { data } = await adminApiClient.get<UserResponse>(
      `/setting/user/${encodeURIComponent(id)}`
    )
    return data
  }

  static async acceptTOS() {
    const { data } = await adminApiClient.post<UserResponse>(
      `/setting/user/tos`
    )

    return data
  }
}
