import { Facility } from '@interfaces/facility'
import { CalculationDateFilter } from '@interfaces/manage-monitor-filter-calculation-date'
import { FacilityFilter } from '@interfaces/manage-monitor-filter-facility'

import manageApiClient from './client'

export class MonitorFilterService {
  static async list(params: { slug_name: string }) {
    const { data } = await manageApiClient.get<FacilityFilter[]>(
      `/filters/${params.slug_name}`
    )

    return data
  }

  static async calculationDate(params: { slug_name: string }) {
    const { slug_name } = params
    const { data } = await manageApiClient.get<CalculationDateFilter[]>(
      `/filters/${slug_name}/calculation-date`
    )

    return data
  }

  static async getLatestCalculationDateForFacility(
    facility?: Facility
  ): Promise<string | undefined> {
    if (!facility) {
      return undefined
    }
    const { data } = await manageApiClient.get<CalculationDateFilter>(
      `/filters/${facility?.slug_name}/${facility?.facility_id}/last-calculation-date`
    )

    const calculationDate = data?.calculation_date
    return calculationDate
  }
}
